var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "gridLayOutDiv" },
    [
      _vm.isShow
        ? _c("avue-crud", {
            ref: "grid",
            attrs: { option: _vm.option, data: _vm.tableData, size: "mini" },
            on: { "on-load": _vm.onLoad },
            scopedSlots: _vm._u(
              [
                {
                  key: "menuRight",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { icon: "el-icon-plus", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.addRow(scope.row)
                            },
                          },
                        },
                        [_vm._v("新增\n      ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-delete" },
                          on: {
                            click: function ($event) {
                              return _vm.removeFile(scope.row)
                            },
                          },
                        },
                        [_vm._v("删 除\n      ")]
                      ),
                    ]
                  },
                },
                {
                  key: "menu",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            icon: "el-icon-delete",
                            type: "text",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.uploadClick(scope.row)
                            },
                          },
                        },
                        [_vm._v("上 传\n      ")]
                      ),
                      scope.row.id != null
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                icon: "el-icon-edit",
                                type: "text",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.downloadFile(scope.row)
                                },
                              },
                            },
                            [_vm._v("下 载\n      ")]
                          )
                        : _vm._e(),
                      scope.row.id != null
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                icon: "el-icon-delete",
                                type: "text",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeFile(scope.row)
                                },
                              },
                            },
                            [_vm._v("删 除\n      ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              68101600
            ),
          })
        : _vm._e(),
      _c("el-upload", {
        ref: "upload",
        staticClass: "upload-demo",
        attrs: {
          "show-file-list": false,
          action: "/api/sinoma-resource/oss/endpoint/put-file-attach",
          multiple: "",
          headers: _vm.uploadHeader,
          "on-success": _vm.uploadSuccess,
          limit: 3,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }